import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Layout Components
import Layout from "../../components/layout/index"
import SEO from "../../components/seo/index"
import Container from "../../components/container"

// Components
import Pagination from "../../components/pagination/index"
import Posts from "../../components/post/grid"

export default ({
  pageContext: {
    pageNumber,
    name,
    slug,
    meta: { yoast_wpseo_metadesc },
    numberOfPages,
  },
  data: {
    allWordpressPost: { nodes: posts },
  },
}) => {
  const [currentPage, setCurrentPage] = useState(pageNumber)

  const categoryTitle = `${name} Archives`

  const pageTitle =
    pageNumber === 0
      ? `${categoryTitle} - Proof Blog`
      : `${categoryTitle} - Page ${pageNumber +
          1} of ${numberOfPages} - Proof Blog`

  return (
    <Layout
      pathPrefix={slug}
      category={{
        name,
        slug,
      }}
    >
      <SEO
        title={pageTitle}
        description={yoast_wpseo_metadesc && yoast_wpseo_metadesc}
        slug={slug}
        paged={pageNumber !== 0 && pageNumber + 1}
      />
      <Container
        sm={`
          padding-top: 1.5rem;
          grid-row-gap: 1.5rem;
        `}
        lg={`
          padding-top: 3rem;
          grid-row-gap: 3rem;
        `}
      >
        <Title>{name}</Title>
        {posts.length > 0 ? (
          <Posts
            posts={posts}
            category={{
              name,
              slug,
            }}
            showAd
          />
        ) : (
          <Error>No results found.</Error>
        )}
        {numberOfPages > 1 && (
          <Pagination
            slug={slug}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numberOfPages={numberOfPages}
          />
        )}
      </Container>
    </Layout>
  )
}

// Styled Components
const Title = styled.h1`
  text-align: center;
`
const Error = styled.p`
  text-align: center;
`

// Page Query
export const pageQuery = graphql`
  query blogPostsByID($skip: Int!, $limit: Int!, $slug: String!) {
    allWordpressPost(
      sort: {order: DESC, fields: date}
      skip: $skip
      limit: $limit
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        ...postCategory
        featured_media {
          ...postCardBg
        }
        author {
          name
          slug
          ...authorAvatar
        }
      }
    }
  }
`
